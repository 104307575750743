import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap'
import { NavLink, withRouter } from 'react-router-dom'

import { toggleStartup, toggleInvestor, setPageType } from '../../redux/home/homeActions'

import { nextStepInvestor } from '../../redux/investorFlowControl/investorFlowControlActions'
import { nextStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'
import { selectedAsStartup } from '../../redux/auth/authActions'
import { selectedAsInvestor } from '../../redux/auth/authActions'

import './HomePage.scss'

/*** Top Baner Images ***/
import banerImgForStartup from '../../assets/images/banerImages/banerImgForStartup.svg'
import banerImgForInverster from '../../assets/images/banerImages/banerImgForInverster.svg'
import banerImgForScout from '../../assets/images/banerImages/banerImgForScout.svg'

/*** Our Co-Investers ***/
import ourCoInvesters01Updated from '../../assets/images/ourCoInvesters/ycombinator.svg'
import ourCoInvesters02Updated from '../../assets/images/ourCoInvesters/500.svg'
import ourCoInvesters03Updated from '../../assets/images/ourCoInvesters/techStars.svg'
import ourCoInvesters04Updated from '../../assets/images/ourCoInvesters/kleiner.svg'
import ourCoInvesters05Updated from '../../assets/images/ourCoInvesters/braincapital.svg'
import ourCoInvesters06Updated from '../../assets/images/ourCoInvesters/a16z.svg'

// Startup Tab Images
import howItWorksStartupIcon01 from '../../assets/images/startup/howItWorksStartupIcon01.svg'
import howItWorksStartupIcon02 from '../../assets/images/startup/howItWorksStartupIcon02.svg'
import howItWorksStartupIcon03 from '../../assets/images/startup/howItWorksStartupIcon03.svg'
import howItWorksStartupIcon04 from '../../assets/images/startup/howItWorksStartupIcon04.svg'
import benefitsStartupIcon01 from '../../assets/images/startup/benefitsStartupIcon01.svg'
import benefitsStartupIcon02 from '../../assets/images/startup/benefitsStartupIcon02.svg'
import benefitsStartupIcon03 from '../../assets/images/startup/benefitsStartupIcon03.svg'
import benefitsStartupIcon04 from '../../assets/images/startup/benefitsStartupIcon04.svg'

// Investers Tab Images
import howItWorksInvesterIcon01 from '../../assets/images/investers/howItWorksInvesterIcon01.svg'
import howItWorksInvesterIcon02 from '../../assets/images/investers/howItWorksInvesterIcon02.svg'
import howItWorksInvesterIcon03 from '../../assets/images/investers/howItWorksInvesterIcon03.svg'
import benefitsInvestersIcon01 from '../../assets/images/investers/benefitsInvestersIcon01.svg'
import benefitsInvestersIcon02 from '../../assets/images/investers/benefitsInvestersIcon02.svg'
import benefitsInvestersIcon03 from '../../assets/images/investers/benefitsInvestersIcon03.svg'
import benefitsInvestersIcon04 from '../../assets/images/investers/benefitsInvestersIcon04.svg'

// Investers Tab Images
import howItWorksScoutsIcon01 from '../../assets/images/scouts/howItWorksScoutsIcon01.svg'
import howItWorksScoutsIcon02 from '../../assets/images/scouts/howItWorksScoutsIcon02.svg'
import howItWorksScoutsIcon03 from '../../assets/images/scouts/howItWorksScoutsIcon03.svg'
import benefitsScoutsIcon01 from '../../assets/images/scouts/benefitsScoutsIcon01.svg'
import benefitsScoutsIcon02 from '../../assets/images/scouts/benefitsScoutsIcon02.svg'
import benefitsScoutsIcon03 from '../../assets/images/scouts/benefitsScoutsIcon03.svg'
import benefitsScoutsIcon04 from '../../assets/images/scouts/benefitsScoutsIcon04.svg'
import whyDoItIcon01 from '../../assets/images/scouts/whyDoItIcon01.svg'
import whyDoItIcon02 from '../../assets/images/scouts/whyDoItIcon02.svg'
import { Helmet } from 'react-helmet'
import OurPriorInvestments from '../OurPriorInvestments'

function Homepage(props) {
  const [pageType, setPageType] = useState(props.pageType || '')

  // set the initial pageType based on props
  useEffect(() => {
    if (pageType) {
      setPageType(props.pageType)
    }
  }, [])

  // update pageType based on the history location pathname
  useEffect(() => {
    if (props.history.location.pathname?.substring(1)) {
      if (props.history.location.pathname?.substring(1) == 'scout') {
        setPageType('scouts')
      } else {
        setPageType(props.history.location.pathname?.substring(1))
      }
    } else {
      setPageType('startup')
    }
  }, [props.history.location.pathname?.substring(1)])

  /**
   * Function to handle tab changes.
   * It updates the history state, sets the pageType, and calls a function to update the pageType in props.
   *
   * @param {string} item - The new pageType to set.
   */
  const onTabChange = item => {
    if (item == 'scouts') {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({ Title: 'scout', Url: 'scout' }, 'scout', 'scout')
    } else {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({ Title: item, Url: item }, item, item)
    }
    setPageType(item)
    props.setPageType(item)
  }
  return (
    <div>
      {/* exitfund.com */}
      {props.history.location.pathname?.substring(1) === '' && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Funding for Top Startups | Angel Investor Network | Exitfund</title>
          <meta
            name="description"
            content="Top startups raise capital from our VC fund and angel investor network. Gain
          value-add in the form of strategic introductions and expert mentorship. Apply now."
          />
          <meta
            name="keywords"
            content="funding programs for startups,funding for early stage startups,Series A funding for startups,Series A funding,Pre-seed funding,Seed funding,Seed funding vcs"
          />
        </Helmet>
      )}
      {/* exitfund.com/startup */}
      {props.history.location.pathname?.substring(1) === 'startup' && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Startup Funding | Seed Funding for Startups | Exitfund</title>
          <meta
            name="description"
            content="We Invest in Innovative Startups and Help Founders Raise Additional Funding"
          />
          <meta
            name="keywords"
            content="Startup funding,Funding for startups,Seed funding for startups,Pre-seed funding for startups,Funding for startup businesses,Startup funding for small businesses,Funding for startup companies"
          />
        </Helmet>
      )}
      {/* exitfund.com/investor */}
      {props.history.location.pathname?.substring(1) === 'investor' && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Top Angel Investment Network | Invest in Startups | Exitfund</title>
          <meta
            name="description"
            content="Invest In Our Vetted Startup Investment Opportunities At Record Low Carry And Fees"
          />
          <meta
            name="keywords"
            content="Investing in startups,Angel investors,Top angel investors,Angel investors network,Angel investor platform,Become an angel investor,Best angel investors"
          />
        </Helmet>
      )}
      {/* exitfund.com/scout */}
      {props.history.location.pathname?.substring(1) === 'scout' && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Top VC Scout Program | Become a VC Scout | Exitfund</title>
          <meta
            name="description"
            content="Break into venture capital through our VC scout program. Earn carry and cash rewards
          for referring startups and investors to our platform. Get started now."
          />
          <meta
            name="keywords"
            content="vc scout,vc scout program,vc scout compensation,vc scout jobs,scout for vc firm,startup scout,startup scouting platform"
          />
        </Helmet>
      )}
      {/* <Header /> */}
      <section className="topBanerSection">
        {props.history.location.pathname?.substring(1) === '' && (
          <>
            <h1 className="d-none">Startup Fundraising Platform</h1>
            <h2 className="d-none">Here's How Startup Fundraising Works</h2>
            <h3 className="d-none">Benefits of Fundraising for Startups</h3>
          </>
        )}
        {props.history.location.pathname?.substring(1) === 'startup' && (
          <>
            <h1 className="d-none">Pre-seed & Seed Funding For Startups</h1>
            <h2 className="d-none">Here's How Startup Funding Works</h2>
            <h3 className="d-none">Benefits of Funding for Startup Companies</h3>
          </>
        )}
        {props.history.location.pathname?.substring(1) === 'investor' && (
          <>
            <h1 className="d-none">Angel Investors Network</h1>
            <h2 className="d-none">Here's How Investing in Startups Works</h2>
            <h3 className="d-none">Benefits of Being an Angel Investor</h3>
          </>
        )}
        {props.history.location.pathname?.substring(1) === 'scout' && (
          <>
            <h1 className="d-none">Venture Scout Program</h1>
            <h2 className="d-none">Here's How the VC Scout Program Works</h2>
            <h3 className="d-none">Benefits of Being a VC Scout</h3>
          </>
        )}
        <Container>
          <Row>
            <Col className="left col-md-6 col-sm-12">
              <ButtonGroup aria-label="">
                <Button
                  variant="outline-dark"
                  className={pageType === 'startup' ? 'active-btn' : 'btn'}
                  onClick={() => {
                    onTabChange('startup')
                  }}
                  size="lg"
                >
                  Startup
                </Button>
                <Button
                  variant="outline-dark"
                  className={pageType === 'investor' ? 'active-btn' : 'btn'}
                  onClick={() => {
                    onTabChange('investor')
                  }}
                  size="lg"
                >
                  Investor
                </Button>
                <Button
                  variant="outline-dark"
                  className={pageType === 'scouts' ? 'active-btn' : 'btn'}
                  onClick={() => {
                    onTabChange('scouts')
                  }}
                  size="lg"
                >
                  Scout
                </Button>
              </ButtonGroup>

              {(pageType === 'startup' || pageType === '') && (
                <div className="page-text">
                  <div
                    className="main-title font-weight-bolder text-capitalize"
                    style={{ animation: `fadeIn 1.2s` }}
                  >
                    Your Gateway to Capital
                  </div>

                  <h5 style={{ animation: `fadeIn 1.2s` }}>
                    We Invest in Innovative Startups and Help Founders Raise Additional Funding
                  </h5>

                  <NavLink
                    to="/startup/signup"
                    className="btn btn-outline-dark font-weight-bold rounded-pill border-dark refer-btn applyNowButton"
                    style={{ textDecoration: 'none', animation: `fadeIn 1.2s` }}
                  >
                    Apply Now
                  </NavLink>
                </div>
              )}
              {pageType === 'investor' && (
                <div className="page-text">
                  <div
                    className="main-title font-weight-bolder text-capitalize"
                    style={{ animation: `fadeIn 1.2s` }}
                  >
                    Your Gateway to tomorrow
                  </div>

                  <h5 className="" style={{ animation: `fadeIn 1.2s` }}>
                    Invest In Our Vetted Startup Investment Opportunities At Record Low Carry And Fees
                  </h5>

                  <NavLink
                    to="/investor/signup"
                    className="btn btn-outline-dark font-weight-bold rounded-pill border-dark request-btn applyNowButton"
                    style={{ textDecoration: 'none', animation: `fadeIn 1.2s` }}
                  >
                    Request Access
                  </NavLink>
                </div>
              )}
              {pageType === 'scouts' && (
                <div className="page-text">
                  <div
                    className="main-title font-weight-bolder text-capitalize"
                    style={{ animation: `fadeIn 1.2s` }}
                  >
                    Your Gateway To VC
                  </div>

                  <h5 className="text-capitalize" style={{ animation: `fadeIn 1.2s` }}>
                    Help us find the best startups and investors
                  </h5>

                  <NavLink
                    to="/scout/signup"
                    className="btn btn-outline-dark font-weight-bold rounded-pill border-dark refer-btn applyNowButton"
                    style={{ textDecoration: 'none', animation: `fadeIn 1.2s` }}
                  >
                    Refer Now
                  </NavLink>
                </div>
              )}
            </Col>
            <Col className="right col-md-6 col-sm-12" style={{ animation: `fadeIn 1.2s` }}>
              <div className="img-wrap">
                {(pageType === '' || pageType === 'startup') && (
                  <img
                    src={banerImgForStartup}
                    style={{ animation: `fadeIn 1.2s` }}
                    className="img-fluid"
                    alt="Startup Funding - Exitfund"
                  ></img>
                )}
                {pageType === 'investor' && (
                  <img
                    src={banerImgForInverster}
                    style={{ animation: `fadeIn 1.2s` }}
                    className="img-fluid"
                    alt="Angel Investor - Exitfund"
                  ></img>
                )}
                {pageType === 'scouts' && (
                  <img
                    src={banerImgForScout}
                    style={{ animation: `fadeIn 1.2s` }}
                    className="img-fluid"
                    alt="Startup Scout - Exitfund"
                  ></img>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="ourPriorInvesters">
        <Container>
          <Row>
            <Col>
              <h5 className="text-capitalize ">Our Prior Investments include</h5>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs="auto" sm="auto" md={9} className="mx-auto">
              <OurPriorInvestments />
            </Col>
          </Row>
        </Container>
      </section>

      {(pageType === '' || pageType === 'startup') && (
        <>
          {/* Startup How it works */}
          <section className="howItWorks">
            <Container>
              <Row>
                <Col>
                  <h2 className="font-weight-bold mb-5 pb-3 sectionHeader">Here's How It Works</h2>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={12} sm={12} className="howItWorksSet">
                  <img src={howItWorksStartupIcon02} alt="investor img"></img>
                  <p>Participate in our due diligence process</p>
                </Col>
                <Col lg={4} md={12} sm={12} className="howItWorksSet">
                  <img src={howItWorksStartupIcon04} alt="investor img"></img>
                  <p>Raise Capital From Exitfund and Investors</p>
                </Col>
                <Col lg={4} md={12} sm={12} className="howItWorksSet">
                  <img src={howItWorksStartupIcon03} alt="investor img"></img>
                  <p>Become A Part Of Our Portfolio Community</p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="ourCoInvesters">
            <Container>
              <Row>
                <Col>
                  <h5 className="text-capitalize">Our co-investors include</h5>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="justify-content-md-center">
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters01} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters02} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters01Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters02Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters03} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters03Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters04} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters05} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters04Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters05Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters06} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters06Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
              </Row>
            </Container>
          </section>
          <section className="benefits py-5">
            <Container className="py-5">
              <Row>
                <Col>
                  <h2 className="font-weight-bold mb-5 pb-3 sectionHeader">Benefits</h2>
                </Col>
              </Row>
              <Row>
                <Col sm lg={6} className="benefitSet mb-5 pb-4">
                  <Row>
                    <Col sm={3} className="left">
                      <img src={benefitsStartupIcon01} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col sm={7} className="right">
                      <h5 className="font-weight-bold">Efficiency</h5>
                      <p>We make decisions in less than a month.</p>
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} lg={6} sm={12} className="benefitSet ">
                  <Row>
                    <Col sm={3} className="left">
                      <img src={benefitsStartupIcon02} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col sm={7} className="right">
                      <h5 className="font-weight-bold">Mentorship</h5>
                      <p>We provide feedback and support to help you succeed.</p>
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} lg={6} sm={12} className="benefitSet">
                  <Row>
                    <Col sm={3} className="left">
                      <img src={benefitsStartupIcon03} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col sm={7} className="right">
                      <h5 className="font-weight-bold">Accessibility</h5>
                      <p>No warm intros required - everyone goes through the same process.</p>
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} lg={6} sm={12} className="benefitSet">
                  <Row>
                    <Col sm={3} className="left">
                      <img src={benefitsStartupIcon04} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col sm={7} className="right">
                      <h5 className="font-weight-bold">Community</h5>
                      <p>We become your tribe and provide value wherever we can.</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="bottomBtnRequest">
            <Container className="py-5">
              <Row>
                <Col className="text-center">
                  <NavLink className="applyNowButton" to="/startup/signup">
                    Apply Now
                  </NavLink>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}

      {pageType === 'investor' && (
        <>
          {/* Investor How it works */}
          <section className="howItWorks py-5">
            <Container className="py-5">
              <Row>
                <Col>
                  <h2 className="font-weight-bold mb-5 pb-3 sectionHeader">Here's How It Works</h2>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={12} className="howItWorksSet">
                  <img src={howItWorksInvesterIcon01} alt="investor img"></img>
                  <p>Complete our online application</p>
                </Col>
                <Col lg={4} md={12} className="howItWorksSet">
                  <img src={howItWorksInvesterIcon02} alt="investor img"></img>
                  <p>Share your investment history and preferences</p>
                </Col>
                <Col lg={4} md={12} className="howItWorksSet">
                  <img src={howItWorksInvesterIcon03} alt="investor img"></img>
                  <p>Gain access to exclusive investment opportunities and thorough due diligence</p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="ourCoInvesters">
            <Container>
              <Row>
                <Col>
                  <h5 className="text-capitalize">Our co-investors include</h5>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="justify-content-md-center">
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters01} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters02} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters01Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters02Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters03} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters03Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters04} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters05} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters04Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters05Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters06} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters06Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
              </Row>
            </Container>
          </section>
          <section className="benefits py-5">
            <Container className="py-5">
              <Row>
                <Col>
                  <h2 className="font-weight-bold mb-5 pb-3 sectionHeader">Benefits</h2>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12} className="benefitSet mb-5 pb-4">
                  <Row>
                    <Col sm={3} className="left">
                      <img src={benefitsInvestersIcon01} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col sm={8} className="right">
                      <h5 className="font-weight-bold">Education Driven</h5>
                      <p>Our platform provides a footing ground for new startup investors.</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12} className="benefitSet mb-5 pb-4">
                  <Row>
                    <Col sm={3} className="left">
                      <img src={benefitsInvestersIcon02} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col sm={8} className="right">
                      <h5 className="font-weight-bold">Low Investment Minimum</h5>
                      <p>You can invest in upcoming startups for as low as $1,000.</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12} className="benefitSet mb-5 pb-4">
                  <Row>
                    <Col sm={3} className="left">
                      <img src={benefitsInvestersIcon03} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col sm={8} className="right">
                      <h5 className="font-weight-bold">Remote</h5>
                      <p>Our virtual and global setting lets you participate from anywhere.</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12} className="benefitSet mb-5 pb-4">
                  <Row>
                    <Col sm={3} className="left">
                      <img src={benefitsInvestersIcon04} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col sm={8} className="right">
                      <h5 className="font-weight-bold">Interactive</h5>
                      <p>Communicate directly with startups to help answer any questions.</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="bottomBtnRequest">
            <Container className="py-5">
              <Row>
                <Col className="text-center">
                  <NavLink className="applyNowButton" to="/investor/signup">
                    {/* Request Access */}
                    Apply Now
                  </NavLink>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}

      {pageType === 'scouts' && (
        <>
          {/* Scouts How it works Section */}
          <section className="howItWorks">
            <Container>
              <Row>
                <Col>
                  <h2 className="font-weight-bold pb-3 sectionHeader">How it works</h2>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4}>
                  <div className="howItWorksSet">
                    <img src={howItWorksScoutsIcon01} alt="investor img"></img>
                    <p>Complete a short sign up</p>
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="howItWorksSet">
                    <img src={howItWorksScoutsIcon02} alt="investor img"></img>
                    <p>Get a unique invite code</p>
                  </div>
                </Col>
                <Col sm={12} md={4}>
                  <div className="howItWorksSet">
                    <img src={howItWorksScoutsIcon03} alt="investor img"></img>
                    <p>Share it with startups and investors</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="ourCoInvesters">
            <Container>
              <Row>
                <Col>
                  <h5 className="text-capitalize">Our co-investors include</h5>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="justify-content-md-center">
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters01} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters02} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters01Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters02Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters03} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters03Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters04} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters05} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters04Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters05Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
                {/* <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters06} alt="our Co-Investers" height="50" width="auto" />
                </Col> */}
                <Col xs={4} sm={4} md="auto">
                  <img src={ourCoInvesters06Updated} alt="our Co-Investers" height="50" width="auto" />
                </Col>
              </Row>
            </Container>
          </section>

          {/* Scouts Why Do It Section */}
          <section className="whyDoIt">
            <Container>
              <Row>
                <Col className="text-center">
                  <h2 className="font-weight-bold pb-3 sectionHeader">Why Do It</h2>
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <Row className="whyDoItInnerSet">
                    <Col xl={2} sm={2} className="left">
                      <img src={whyDoItIcon01} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col xl={8} sm={10} className="right text-capitalize">
                      {/* <p className="mb-0">
                        For each startup that you refer, receive up to{' '}
                        <span className="font-weight-bolder h2">25%</span> of our carry when we invest and
                        lead a syndicate in the startup
                      </p> */}
                      <p className="mb-0">
                        Get up to <span className="font-weight-bolder h2">50%</span> of our carry for helping us find startups and investors after Reaching Certain Goals
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <Row className="whyDoItInnerSet">
                    <Col xl={2} sm={2} className="left">
                      <img src={whyDoItIcon02} className="img-fluid" alt="investor"></img>
                    </Col>
                    <Col xl={8} sm={10} className="right text-capitalize">
                      {/* <p className="mb-0">
                        For each investor that you refer, receive up to{' '}
                        <span className="font-weight-bolder h2">50%</span> of our carry on their first
                        investment
                      </p> */}
                      <p className="mb-0">You do not need to be accredited to receive carry</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Scouts Additional Benefits Section */}
          <section className="benefits">
            <Container>
              <Row>
                <Col>
                  <h2 className="font-weight-bold pb-3 sectionHeader">Additional Benefits</h2>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12} className="benefitSet">
                  <Row>
                    <Col sm={12}>
                      <div className="icon-details">
                        <div className="left icon-1">
                          <img src={benefitsScoutsIcon01} className="img-fluid" alt="investor"></img>
                        </div>

                        <div className="right">
                          <h5 className="font-weight-bold benefits-title">Easy</h5>
                          <p>Get started immediately.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12} className="benefitSet">
                  <Row>
                    <Col sm={12}>
                      <div className="icon-details icon-details-left">
                        <div className="left icon-2">
                          <img src={benefitsScoutsIcon02} className="img-fluid" alt="investor"></img>
                        </div>
                        <div className="right">
                          <h5 className="font-weight-bold benefits-title">Hands-on</h5>
                          <p>Learn how to spot startups and investors with great potential.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12} className="benefitSet">
                  <Row>
                    <Col sm={12}>
                      <div className="icon-details">
                        <div className="left icon-3">
                          <img src={benefitsScoutsIcon03} className="img-fluid" alt="investor"></img>
                        </div>

                        <div className="right">
                          <h5 className="font-weight-bold benefits-title">Transparent</h5>
                          <p>Keep an eye on your startups and investors through your own dashboard.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} sm={12} className="benefitSet">
                  <Row>
                    <Col sm={12}>
                      <div className="icon-details icon-details-left">
                        <div className="left icon-4">
                          <img src={benefitsScoutsIcon04} className="img-fluid" alt="investor"></img>
                        </div>
                        <div className="right">
                          <h5 className="font-weight-bold benefits-title">Streamlined</h5>
                          <p>All agreements are signed online in one simple process.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Scouts Get Started Button */}
          <section className="bottomBtnRequest">
            <Container>
              <Row>
                <Col className="text-center">
                  <NavLink className="applyNowButton" to="/scout/signup">
                    {/* Get Started */}
                    Apply Now
                  </NavLink>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  investorPage: state.home.investorPage,
  pageTypeValue: state.home.pageType,
})

const mapDispatchToProps = dispatch => ({
  toggleStartupPage: () => dispatch(toggleStartup()),
  toggleInvestorPage: () => dispatch(toggleInvestor()),
  nextStepInvestor: () => dispatch(nextStepInvestor()),
  getStartedAsInvestor: () => dispatch(selectedAsInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  getStartedAsStartup: () => dispatch(selectedAsStartup()),
  setPageType: pageType => dispatch(setPageType(pageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Homepage))
